import React from 'react'
import { useParams } from 'react-router-dom'
import useLocale from './useLocale'
import Configuration from '../models/Configuration'
import { toast } from 'react-toastify'

function useConfig () {
  const { id } = useParams()
  const { l } = useLocale()
  const [config, setConfig] = React.useState({
    name: '',
    smtp: {},
    user: {},
    general: {},
    gamification: {
      enabled: false,
      qualities: [],
      dificulties: []
    }
  })

  function getConfig () {
    Configuration.show(id)
      .then(res => setConfig(res))
      .catch(err => toast.error(l('general_error')))
  }

  React.useEffect(() => {
    getConfig()
  }, [])

  function handleChange (e, config) {
    const { name, value } = e.target
    setConfig(old => ({
      ...old,
      [config]: {
        ...old[config],
        [name]: value,
      },
    }))
  }

  function handleCheckboxChange (e, config) {
    const { checked, name } = e.target
    setConfig(old => ({
      ...old,
      [config]: {
        ...old[config],
        [name]: checked,
      },
    }))
  }

  function handleSave () {
    Configuration.update(config, id)
      .then(() => {
        toast.success(l('config_added'))
        getConfig()
      })
      .catch(() => toast.error(l('general_error')))
  }

  function handleNameChange (e) {
    const { name, value } = e.target
    setConfig(old => ({
      ...old,
      [name]: value,
    }))
  }

  function addClassifier (type, id) {
    const aux = config
    aux.gamification[type].push(id)
    setConfig({ ...aux })
    handleSave()
  }

  function removeClassifier (type, id) {
    const aux = config
    const index = aux.gamification[type].map(item => item._id).indexOf(id)
    aux.gamification[type].splice(index, 1)
    setConfig({ ...aux })
    handleSave()
  }

  return {
    config,
    handleChange,
    handleSave,
    handleNameChange,
    handleCheckboxChange,
    addClassifier,
    removeClassifier
  }
}

export default useConfig
