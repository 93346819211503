import React from 'react'
import Card from '../../components/Card'
import { H2, PageHeading } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { Input } from '../../components/FormComponents'
import CheckRole from '../../components/CheckRole'
import DangerButton from '../../components/DangerButton'
import { Link } from 'react-router-dom'
import useConfigurations from '../../hooks/useConfigurations'
import Paginator from '../../components/Paginator'

function Config() {
	const { l } = useLocale()
	const {
		openModal,
		configSets,
		handleSave,
		handleDelete,
		nameRef,
		closeModal,
		showModal,
		setPage,
		setSearch,
		search,
	} = useConfigurations()

	return (
		<>
			<PageHeading image='/img/cog.svg' title={l('config')}>
				<CheckRole has={'add_config'}>
					<Button onClick={openModal}>{l('add')}</Button>
				</CheckRole>
			</PageHeading>
			<Card>
				<Input
					className='w-64'
					value={search}
					onChange={e => setSearch(e.target.value)}
					placeholder={l('search')}
				/>
				<table className='w-full'>
					<thead>
						<tr className={'text-left border-b border-gray-600'}>
							<th>{l('name')}</th>
							<th>{l('actions')}</th>
						</tr>
					</thead>
					<tbody>
						{configSets.docs?.map(set => (
							<tr key={set._id}>
								<td>{set.name}</td>
								<td className={'flex gap-3'}>
									<CheckRole has={'view_config'}>
										<Link
											to={`/configuration/${set._id}`}
											className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
										>
											<img className='w-[25px]' src='/img/view.svg' alt='' />
										</Link>
									</CheckRole>
									<CheckRole has={'delete_config'}>
										<DangerButton onClick={() => handleDelete(set._id)}>
											<img src='/img/trash.svg' className={'w-[20px]'} alt='' />
										</DangerButton>
									</CheckRole>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<Paginator meta={configSets} setPage={setPage} />
			</Card>
			<Modal show={showModal} onClose={closeModal}>
				<H2>{l('add_config')}</H2>
				<form onSubmit={handleSave}>
					<Input label={l('name')} reference={nameRef} />
					<Button full type={'secondary'}>
						{l('save')}
					</Button>
				</form>
			</Modal>
		</>
	)
}

export default Config
