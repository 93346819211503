import React from "react";
import useModal from "./useModal";
import { slugify } from "../utils";

const answerBoilerplate = {
  answer: "",
  isCorrect: false,
};

const preQuestionInit = {
  question: "",
  slug: "",
  justification: "",
  possibleAnswers: [answerBoilerplate],
};

function useQuestions({ setForm, form }) {
  const viewQeustions = useModal();
  const addQuestion = useModal();
  const currentItem = React.useRef(null);
  const editingQuestion = React.useRef(null);
  const [generalJustification, setGeneralJustification] = React.useState("");
  const [preQuestion, setPreQuestion] = React.useState(
    JSON.parse(JSON.stringify(preQuestionInit)),
  );
  const [currentQuestions, setCurrentQuestions] = React.useState([]);

  function handleViewQuestions(item, key, subKey = null) {
    const { questions } = item.attributes;
    setCurrentQuestions(questions || []);
    setGeneralJustification(
      item.attributes.question_general_justification || "",
    );
    currentItem.current = {
      key,
      subKey,
    };
    addQuestion.closeModal();
    viewQeustions.openModal();
  }

  function handleAddQuestion() {
    editingQuestion.current = null;
    viewQeustions.closeModal();
    addQuestion.openModal();
  }

  function questionHandleChange(e) {
    const { name, value } = e.target;
    const aux = preQuestion;
    aux[name] = value;
    if (name === "question" && editingQuestion.current === null)
      aux.slug = slugify(value);
    setPreQuestion((old) => ({
      ...old,
      [name]: value,
    }));
  }

  function answerHandleChange(e, index) {
    const { name, value } = e.target;
    const aux = preQuestion;
    aux.possibleAnswers[index][name] = value;
    setPreQuestion({ ...aux });
  }

  function answerCheckboxChange(e, index) {
    const { name, checked } = e.target;
    const aux = preQuestion;
    aux.possibleAnswers = aux.possibleAnswers.map((answer) => ({
      ...answer,
      isCorrect: false,
    }));
    aux.possibleAnswers[index][name] = checked;
    setPreQuestion({ ...aux });
  }

  function addPossibleAnswer() {
    const aux = preQuestion;
    aux.possibleAnswers.push(JSON.parse(JSON.stringify(answerBoilerplate)));
    setPreQuestion({ ...aux });
  }

  function removePossibleAnswer(index) {
    const aux = preQuestion;
    if (aux.possibleAnswers.length === 1) return;
    aux.possibleAnswers.splice(index, 1);
    setPreQuestion({ ...aux });
  }

  function handleAddAnswer() {
    const { key, subKey } = currentItem.current;
    const aux = form;
    const editingIndex = editingQuestion.current;

    if (editingIndex === null) {
      if (subKey === null) {
        if (!aux.items[key].attributes.questions)
          aux.items[key].attributes.questions = [];
        aux.items[key].attributes.questions.push(preQuestion);
      } else {
        if (!aux.items[key].items[subKey].attributes.questions)
          aux.items[key].items[subKey].attributes.questions = [];
        aux.items[key].items[subKey].attributes.questions.push(preQuestion);
      }
    } else {
      if (subKey === null) {
        aux.items[key].attributes.questions[editingIndex] = preQuestion;
      } else {
        aux.items[key].items[subKey].attributes.questions[editingIndex] =
          preQuestion;
      }
    }

    setForm({ ...aux });
    clearQuestionForm();
    addQuestion.closeModal();
  }

  function clearQuestionForm() {
    setPreQuestion(preQuestionInit);
  }

  function handleRemove(index) {
    const { key, subKey } = currentItem.current;
    const aux = form;

    let currQuestions = [];
    if (subKey === null) {
      aux.items[key].attributes.questions.splice(index, 1);
      currQuestions = aux.items[key].attributes.questions;
    } else {
      aux.items[key].items[subKey].attributes.questions.splice(index, 1);
      currQuestions = aux.items[key].items[subKey].attributes.questions;
    }

    setForm({ ...aux });
    setCurrentQuestions(currQuestions);
  }

  function handleView(question, index) {
    editingQuestion.current = index;
    setPreQuestion(question);
    viewQeustions.closeModal();
    addQuestion.openModal();
  }

  function handleAddGeneralJustification(e) {
    const { value } = e.target;
    const { key, subKey } = currentItem.current;
    const aux = form;

    if (subKey === null) {
      aux.items[key].attributes.question_general_justification = value;
    } else {
      aux.items[key].items[subKey].attributes.question_general_justification =
        value;
    }
    setGeneralJustification(value);
    setForm({ ...aux });
  }

  return {
    handleViewQuestions,
    viewQeustions,
    currentQuestions,
    handleView,
    handleRemove,
    handleAddQuestion,
    addQuestion,
    questionHandleChange,
    preQuestion,
    answerHandleChange,
    answerCheckboxChange,
    removePossibleAnswer,
    addPossibleAnswer,
    handleAddAnswer,
    editingQuestion,
    handleAddGeneralJustification,
    generalJustification,
  };
}

export default useQuestions;
