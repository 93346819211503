import React from 'react'
import { PageHeading } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Card from '../../components/Card'
import { Link } from 'react-router-dom'
import useForms from '../../hooks/useForms'
import Modal from '../../components/Modal'
import { Input } from '../../components/FormComponents'
import CheckRole from '../../components/CheckRole'
import Button from '../../components/Button'
import DangerButton from '../../components/DangerButton'
import CheckFormPermission from '../../components/CheckFormPermission'
import Paginator from '../../components/Paginator'

function FormsPage() {
	const { l } = useLocale()
	const {
		forms,
		handleDelete,
		handleAdd,
		nameRef,
		openModal,
		closeModal,
		showModal,
		setPage,
		setSearch,
		search,
	} = useForms()

	return (
		<>
			<PageHeading title={l('form')} image='/img/form.svg'>
				<CheckRole has={'add_forms'}>
					<Button onClick={openModal}>{l('add')}</Button>
				</CheckRole>
			</PageHeading>
			<Card>
				<Input
					className='w-64'
					value={search}
					onChange={e => setSearch(e.target.value)}
					placeholder={l('search')}
				/>
				<table className='w-full'>
					<thead>
						<tr className='text-left border-b border-gray-600'>
							<th>{l('name')}</th>
							<th>{l('actions')}</th>
						</tr>
					</thead>
					<tbody>
						{forms.docs?.map(form => (
							<tr key={form._id}>
								<td>{form.name}</td>
								<td className='flex gap-3'>
									<CheckRole has='view_forms'>
										<Link
											to={`/form/${form._id}`}
											className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
										>
											<img className='w-[25px]' src='/img/view.svg' alt='' />
										</Link>
									</CheckRole>
									<CheckRole has='delete_forms'>
										<DangerButton onClick={() => handleDelete(form._id)} />
									</CheckRole>
									<CheckRole has='answer_forms'>
										<CheckFormPermission has={form._id}>
											<Link
												to={`/form/${form._id}/answer`}
												className='bg-gray-500 hover:bg-gray-400 px-2 py-1 rounded-md ease-in-out duration-200'
											>
												<img src='/img/fill.svg' className='w-[25px]' alt='' />
											</Link>
										</CheckFormPermission>
									</CheckRole>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<Paginator meta={forms} setPage={setPage} />
			</Card>

			<Modal show={showModal} onClose={closeModal}>
				<Input label={l('name')} reference={nameRef} />
				<Button onClick={handleAdd} type='secondary' full>
					{l('add')}
				</Button>
			</Modal>
		</>
	)
}

export default FormsPage
