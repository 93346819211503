import React from 'react'
import useLocale from '../../hooks/useLocale'
import {PageHeading} from '../../components/Headings'
import Card from '../../components/Card'
import CheckRole from '../../components/CheckRole'
import {Link} from 'react-router-dom'
import useClinicCases from '../../hooks/useClinicCases'
import DangerButton from '../../components/DangerButton'
import {textColor} from '../../utils'
import useStates from '../../hooks/useStates'
import {Input, Select} from '../../components/FormComponents'
import AppContext from '../../contexts/AppContext'
import useForms from '../../hooks/useForms'
import Paginator from '../../components/Paginator'
import ExportClinicCases from '../../components/ExportClinicCases'
import ViewModeButton from '../../components/ViewModeButton'

function ClinicCases () {
  const {l} = useLocale()
  const {clinicCases, filters, handleDelete, handleChange, setPage, toggleViewMode, viewMode} =
    useClinicCases()
  const {states} = useStates(false)
  const {forms} = useForms({paginate: false})
  const {viewStates, forms: userForms} = React.useContext(AppContext)

  return (
    <>
      <PageHeading title={l('clinic_cases')} image='/img/clinic_case.svg'>
        <CheckRole has={'export_clinic_cases'}>
          <ExportClinicCases form={filters.form} />
        </CheckRole>
        <ViewModeButton viewMode={viewMode} toggle={toggleViewMode} />
      </PageHeading>
      <Card>
        <div className='flex gap-3'>
          <Input
            name={'name'}
            label={l('user')}
            value={filters.name}
            onChange={handleChange}
          />
          <Select
            options={states.filter(state => viewStates.includes(state._id))}
            label={l('state')}
            labelKey={'name'}
            valueKey={'_id'}
            onChange={handleChange}
            name={'state'}
            value={filters.state}
          />
          <Select
            options={forms.filter(form => userForms.includes(form._id))}
            label={l('form')}
            labelKey={'name'}
            valueKey={'_id'}
            onChange={handleChange}
            name={'form'}
            value={filters.form}
          />
        </div>
        <table className='w-full'>
          <thead>
            <tr className='text-left border-b border-gray-600'>
              <th>{l('form')}</th>
              <th>{l('title')}</th>
              <th>{l('user')}</th>
              <th>{l('state')}</th>
              <th>{l('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {clinicCases.docs?.map(clinicCase => (
              <>
                <tr>
                  <td>{clinicCase.form?.name}</td>
                  <td>{clinicCase.title}</td>
                  <td>{clinicCase.user?.name}</td>
                  <td>
                    <span
                      style={{
                        background: clinicCase.state?.color,
                        color: textColor(clinicCase.state?.color),
                      }}
                      className='px-4 py-0.5 rounded-full inline-block min-w-[120px] text-center'
                    >
                      {clinicCase.state?.name}
                    </span>
                  </td>
                  <td className='flex gap-3'>
                    <CheckRole has='view_clinic_cases'>
                      <Link
                        to={`/clinic-cases/${clinicCase._id}`}
                        className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
                      >
                        <img className='w-[25px]' src='/img/view.svg' alt='' />
                      </Link>
                    </CheckRole>
                    <CheckRole has='delete_clinic_cases'>
                      <DangerButton
                        onClick={() => handleDelete(clinicCase._id)}
                      />
                    </CheckRole>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
        <Paginator meta={clinicCases} setPage={setPage} />
      </Card>
    </>
  )
}

export default ClinicCases
