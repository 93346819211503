import React from 'react'
import useModal from "./useModal";
import Configuration from "../models/Configuration";
import { toast } from "react-toastify";
import useLocale from "./useLocale";

function useConfigurations(paginate = true) {
  const { l } = useLocale()
  const [ configSets, setConfigSets ] = React.useState([])
  const { showModal, openModal, closeModal } = useModal()
  const [ page, setPage ] = React.useState(1)
  const [ search, setSearch ] = React.useState('')
  const nameRef = React.useRef(null)

  const getConfigs = () => {
    Configuration.get(search, page, paginate)
      .then(res => setConfigSets(res))
      .catch(err => toast.error(l('general_error')))
  }

  React.useEffect(() => {
    getConfigs()
  }, [ page, search ])

  const handleSave = (e) => {
    e.preventDefault()

    const { value } = nameRef.current
    Configuration.create({ name: value })
      .then(res => {
        toast.success(l('config_added'))
        closeModal()
        nameRef.current.value = ''
        getConfigs()
      })
      .catch(err => toast.error(l('general_error')))
  }

  const handleDelete = id => {
    Configuration.delete(id).then(() => {
      getConfigs()
      toast.success(l('config_deleted'))
    })
  }

  return { handleDelete, openModal, closeModal, showModal, handleSave, nameRef, configSets, setPage, setSearch, search }
}

export default useConfigurations