import Fetch from "./Fetch"

class Configuration {
  fetch = null

  static {
    this.fetch = new Fetch()
  }

  static create(data) {
    this.fetch.endpoint = 'configuration'
    this.fetch.method = "POST"
    this.fetch.body = data
    return this.fetch.performRequest()
  }

  static update(data, id) {
    this.fetch.endpoint = `configuration/${ id }`
    this.fetch.method = "PUT"
    this.fetch.body = data
    return this.fetch.performRequest()
  }

  static get(search, page, paginate) {
    this.fetch.endpoint = 'configuration'
    this.fetch.method = "GET"
    this.fetch.queries.set('page', page)
    this.fetch.queries.set('search', search)
    paginate === false && this.fetch.queries.set('paginate', 'false')
    return this.fetch.performRequest()
  }

  static delete(id) {
    this.fetch.endpoint = `configuration/${ id }`
    this.fetch.method = "DELETE"
    return this.fetch.performRequest()
  }

  static show(id) {
    this.fetch.endpoint = `configuration/${ id }`
    this.fetch.method = "GET"
    return this.fetch.performRequest()
  }
}

export default Configuration