import Fetch from './Fetch'

class ClinicCase {
  fetch = null

  static {
    this.fetch = new Fetch()
  }

  static get (search, state, form, page, paginate) {
    this.fetch.endpoint = 'clinic-cases'
    this.fetch.method = 'GET'
    search && this.fetch.queries.set('name', search)
    state && this.fetch.queries.set('state', state)
    form && this.fetch.queries.set('form', form)
    page && this.fetch.queries.set('page', page)
    paginate === false && this.fetch.queries.set('paginate', paginate)
    return this.fetch.performRequest()
  }

  static reassign (userId, caseId) {
    this.fetch.endpoint = `clinic-cases/${caseId}/reassign`
    this.fetch.method = 'POST'
    this.fetch.body = {userId}
    return this.fetch.performRequest()
  }

  static my (search, state, form, page, paginate) {
    this.fetch.endpoint = 'clinic-cases/my'
    this.fetch.method = 'GET'
    this.fetch.queries.set('name', search)
    this.fetch.queries.set('state', state)
    this.fetch.queries.set('form', form)
    this.fetch.queries.set('page', page)
    paginate === false && this.fetch.queries.set('paginate', paginate)
    return this.fetch.performRequest()
  }

  static published (page, paginate = true) {
    this.fetch.endpoint = 'clinic-cases/published'
    this.fetch.method = 'GET'
    // this.fetch.queries.set('name', search)
    // this.fetch.queries.set('state', state)
    // this.fetch.queries.set('form', form)
    this.fetch.queries.set('page', page)
    paginate === false && this.fetch.queries.set('paginate', paginate)
    return this.fetch.performRequest()
  }

  static save ({data, id, action}) {
    const {items} = data

    this.fetch.endpoint = 'clinic-cases'
    this.fetch.method = 'POST'
    this.fetch.body = {
      items,
      form: id,
      action: action,
    }
    return this.fetch.performRequest()
  }

  static show (id) {
    this.fetch.endpoint = `clinic-cases/${id}`
    this.fetch.method = 'GET'
    return this.fetch.performRequest()
  }

  static delete (id) {
    this.fetch.endpoint = `clinic-cases/${id}`
    this.fetch.method = 'DELETE'
    return this.fetch.performRequest()
  }

  static executeAction ({id, action, comment, items, quality, difficulty}) {
    this.fetch.endpoint = `clinic-cases/${id}/${action}`
    this.fetch.method = 'PUT'
    this.fetch.body = {comment, items, quality, difficulty}
    return this.fetch.performRequest()
  }

  static update ({id, data}) {
    this.fetch.endpoint = `clinic-cases/${id}`
    this.fetch.method = 'PUT'
    this.fetch.body = data
    return this.fetch.performRequest()
  }

  static download (id) {
    this.fetch.endpoint = `clinic-cases/${id}/download`
    this.fetch.method = 'GET'
    this.fetch.isDownloadingFile = true
    return this.fetch.performRequest()
  }

  static getFields (form) {
    this.fetch.endpoint = 'clinic-cases/fields'
    this.fetch.method = 'GET'
    this.fetch.queries.set('forms', JSON.stringify(form))
    return this.fetch.performRequest()
  }

  static export (params) {
    this.fetch.endpoint = 'clinic-cases/export'
    this.fetch.method = 'POST'
    this.fetch.body = params
    this.fetch.isDownloadingFile = true
    return this.fetch.performRequest()
  }

  static uploadData (data, caseId) {
    this.fetch.endpoint = `clinic-cases/${caseId}/upload-to-gallery`
    this.fetch.method = 'POST'
    this.fetch.body = data
    this.fetch.headers = {}
    return this.fetch.performRequest()
  }
}

export default ClinicCase
