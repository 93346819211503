import React from 'react'
import useLocale from '../../hooks/useLocale'
import {H2, H3} from '../Headings'
import components from '../../config/components'
import {Checkbox, Input} from '../../components/FormComponents'
import DeleteButton from '../DeleteButton'
import FormButtons from '../FormButtons'
import {Select as SelectField} from '../../components/FormComponents'
import Button from '../Button'

const LayoutSelectOptions = [
  {label: '1 column', value: 1},
  {label: '2 columns', value: 2},
  {label: '3 columns', value: 3},
  {label: '4 columns', value: 4},
  {label: '5 columns', value: 5},
  {label: '6 columns', value: 6},
  {label: '7 columns', value: 7},
  {label: '8 columns', value: 8},
  {label: '9 columns', value: 9},
  {label: '11 columns', value: 11},
  {label: '12 columns', value: 12},
]

export function TextArea ({
  data,
  handleInputChange,
  handleSubInputChange,
  index,
  subIndex,
  subItem = false,
  handleDelete,
  handleMove,
  handleCheckboxChange,
  canHaveQuestions = true
}) {
  const {l} = useLocale()

  const handleChange = e => {
    if (subItem === true) {
      return handleSubInputChange(e, subIndex)
    }

    handleInputChange(e, index)
  }

  const handleDeletion = () => {
    if (subItem === true) {
      return handleDelete(index, subIndex)
    }

    handleDelete(index)
  }

  const handleMotion = action => {
    if (subItem === true) {
      return handleMove(action, index, subIndex)
    }

    handleMove(action, index)
  }

  const handlingCheckboxChange = e => {
    if (subItem === true) {
      return handleCheckboxChange(e, index, subIndex)
    }

    handleCheckboxChange(e, index)
  }

  return (
    <div className='w-full border border-gray-600 rounded-md bg-gray-800 p-4 mt-3 relative'>
      <FormButtons handleMove={handleMotion} handleDeletion={handleDeletion} />
      <H2>{l('textarea')}</H2>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
        {/* <Input
					name='name'
					label={l('name')}
					onChange={handleChange}
					value={data.attributes.name}
				/> */}
        <Input
          name='label'
          label={l('label')}
          onChange={handleChange}
          value={data.attributes.label}
        />
      </div>
      <SelectField
        onChange={handleChange}
        value={data.attributes.cols}
        className={'max-w-xs'}
        label={l('layout_cols')}
        options={LayoutSelectOptions}
        name="cols"
      />
      <div className='flex gap-3'>
        <Checkbox
          id={`required_${index}${subIndex}`}
          name={`is_required`}
          onChange={handlingCheckboxChange}
          label={l('is_required')}
          checked={data.attributes.is_required}
        />
        {canHaveQuestions && <Checkbox
          id={`questions_${index}${subIndex}`}
          name={`has_questions`}
          onChange={handlingCheckboxChange}
          label={l('has_questions')}
          checked={data.attributes.has_questions}
        />}
      </div>
    </div>
  )
}

export function Editor ({
  data,
  handleInputChange,
  handleSubInputChange,
  index,
  subIndex,
  subItem = false,
  handleDelete,
  handleMove,
  handleCheckboxChange,
  canHaveQuestions = true
}) {
  const {l} = useLocale()

  const handleChange = e => {
    if (subItem === true) {
      return handleSubInputChange(e, subIndex)
    }

    handleInputChange(e, index)
  }

  const handleDeletion = () => {
    if (subItem === true) {
      return handleDelete(index, subIndex)
    }

    handleDelete(index)
  }

  const handleMotion = action => {
    if (subItem === true) {
      return handleMove(action, index, subIndex)
    }

    handleMove(action, index)
  }

  const handlingCheckboxChange = e => {
    if (subItem === true) {
      return handleCheckboxChange(e, index, subIndex)
    }

    handleCheckboxChange(e, index)
  }

  return (
    <div className='w-full border border-gray-600 rounded-md bg-gray-800 p-4 mt-3 relative'>
      <FormButtons handleMove={handleMotion} handleDeletion={handleDeletion} />
      <H2>{l('editor')}</H2>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
        {/* <Input
					name='name'
					label={l('name')}
					onChange={handleChange}
					value={data.attributes.name}
				/> */}
        <Input
          name='label'
          label={l('label')}
          onChange={handleChange}
          value={data.attributes.label}
        />
      </div>
      <SelectField
        onChange={handleChange}
        value={data.attributes.cols}
        className={'max-w-xs'}
        label={l('layout_cols')}
        options={LayoutSelectOptions}
        name="cols"
      />
      <div className='flex gap-3'>
        <Checkbox
          id={`required_${index}${subIndex}`}
          name={`is_required`}
          onChange={handlingCheckboxChange}
          label={l('is_required')}
          checked={data.attributes.is_required}
        />
        {subItem === false && <Checkbox
          id={`useAsTitle_${index}${subIndex}`}
          name={`useAsTitle`}
          onChange={handlingCheckboxChange}
          label={l('use_as_title')}
          checked={data.attributes.useAsTitle}
        />}
        {canHaveQuestions && <Checkbox
          id={`questions_${index}${subIndex}`}
          name={`has_questions`}
          onChange={handlingCheckboxChange}
          label={l('has_questions')}
          checked={data.attributes.has_questions}
        />}
      </div>
    </div>
  )
}

export function RadioButtons ({
  handleAddRow,
  handleSubRowAdd,
  data,
  index,
  subIndex,
  handleRadioChange,
  subItem,
  handleSubRadioChange,
  handleSubInputChange,
  handleInputChange,
  handleDelete,
  handleMove,
  handleCheckboxChange,
  canHaveQuestions = true,
}) {
  const {l} = useLocale()

  const item = {
    label: '',
    name: '',
  }

  const handleClick = () => {
    if (subItem === true) {
      return handleSubRowAdd(subIndex, item)
    }
    handleAddRow(index, item)
  }

  const handleChoiceChange = (e, key) => {
    if (subItem === true) {
      return handleSubRadioChange(e, index, subIndex, key)
    }
    handleRadioChange(e, index, key)
  }

  const handleChange = e => {
    if (subItem === true) {
      return handleSubInputChange(e, subIndex)
    }

    handleInputChange(e, index)
  }

  const handleDeletion = () => {
    if (subItem === true) return handleDelete(index, subIndex)
    handleDelete(index)
  }

  const handleDeleteSubItem = key => {
    if (subItem === true) return handleDelete(index, subIndex, key)
    handleDelete(index, null, key)
  }

  const handleMotion = action => {
    if (subItem === true) {
      return handleMove(action, index, subIndex)
    }

    handleMove(action, index)
  }

  const handlingCheckboxChange = e => {
    if (subItem === true) {
      return handleCheckboxChange(e, index, subIndex)
    }

    handleCheckboxChange(e, index)
  }

  return (
    <div className='w-full border border-gray-600 rounded-md bg-gray-800 p-4 mt-3 relative'>
      <FormButtons handleMove={handleMotion} handleDeletion={handleDeletion} />
      <H2>{l('radio_buttons')}</H2>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
        <Input
          name='label'
          label={l('label')}
          onChange={handleChange}
          value={data.attributes.label}
        />
        <Input
          name='name'
          label={l('name')}
          onChange={handleChange}
          value={data.attributes.name}
        />
      </div>
      <SelectField
        onChange={handleChange}
        value={data.attributes.cols}
        className={'max-w-xs'}
        label={l('layout_cols')}
        options={LayoutSelectOptions}
        name="cols"
      />
      <div className='flex gap-3'>
        <Checkbox
          id={`required_${index}${subIndex}`}
          name={`is_required`}
          onChange={handlingCheckboxChange}
          label={l('is_required')}
          checked={data.attributes.is_required}
        />
        {canHaveQuestions && <Checkbox
          id={`questions_${index}${subIndex}`}
          name={`has_questions`}
          onChange={handlingCheckboxChange}
          label={l('has_questions')}
          checked={data.attributes.has_questions}
        />}
      </div>
      {Object.keys(data.attributes.items).map(key => {
        const item = data.attributes.items[key]
        return (
          <div key={key}>
            <div className='flex gap-5'>
              <H3>
                {l('radio')} #{key / 100}
              </H3>
              <DeleteButton
                absolute={false}
                handleDelete={() => handleDeleteSubItem(key)}
              />
            </div>
            <div className='flex flex-row gap-5'>
              <Input
                label={l('label')}
                name='label'
                value={item.label}
                onChange={e => handleChoiceChange(e, key)}
              />
              <Input
                label={l('value')}
                name='value'
                onChange={e => handleChoiceChange(e, key)}
                value={item.value}
              />
            </div>
          </div>
        )
      })}
      <button onClick={handleClick} className='py-0.5 px-2 bg-red-900 rounded'>
        {l('add')}
      </button>
    </div>
  )
}

export function Multiple ({
  data,
  handleSubInputChange,
  handleInputChange,
  subItem,
  index,
  subIndex,
  handleAddRow,
  handleSubRowAdd,
  handleSubRadioChange,
  handleRadioChange,
  handleDelete,
  handleMove,
  handleCheckboxChange,
  canHaveQuestions = true
}) {
  const {l} = useLocale()

  const item = {
    label: '',
    // name: '',
  }

  const handleChange = e => {
    if (subItem === true) {
      return handleSubInputChange(e, subIndex)
    }

    handleInputChange(e, index)
  }

  const handleChoiceChange = (e, key) => {
    if (subItem === true) {
      return handleSubRadioChange(e, index, subIndex, key)
    }
    handleRadioChange(e, index, key)
  }

  const handleClick = () => {
    if (subItem === true) {
      return handleSubRowAdd(subIndex, item)
    }
    handleAddRow(index, item)
  }

  const handleDeletion = () => {
    if (subItem === true) return handleDelete(index, subIndex)
    handleDelete(index)
  }

  const handleDeleteSubItem = key => {
    if (subItem === true) return handleDelete(index, subIndex, key)
    handleDelete(index, null, key)
  }

  const handleMotion = action => {
    if (subItem === true) {
      return handleMove(action, index, subIndex)
    }

    handleMove(action, index)
  }

  const handlingCheckboxChange = e => {
    if (subItem === true) {
      return handleCheckboxChange(e, index, subIndex)
    }

    handleCheckboxChange(e, index)
  }

  return (
    <div className='w-full border border-gray-600 rounded-md bg-gray-800 p-4 mt-3 relative'>
      <FormButtons handleMove={handleMotion} handleDeletion={handleDeletion} />
      <H2>{l('multiple_choice')}</H2>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
        <Input
          name='label'
          label={l('label')}
          onChange={handleChange}
          value={data.attributes.label}
        />
        <Input
          name='name'
          label={l('name')}
          onChange={handleChange}
          value={data.attributes.name}
        />
      </div>
      <SelectField
        onChange={handleChange}
        value={data.attributes.cols}
        className={'max-w-xs'}
        label={l('layout_cols')}
        options={LayoutSelectOptions}
        name="cols"
      />
      <div className='flex gap-3'>
        <Checkbox
          id={`required_${index}${subIndex}`}
          name={`is_required`}
          onChange={handlingCheckboxChange}
          label={l('is_required')}
          checked={data.attributes.is_required}
        />
        {canHaveQuestions && <Checkbox
          id={`questions_${index}${subIndex}`}
          name={`has_questions`}
          onChange={handlingCheckboxChange}
          label={l('has_questions')}
          checked={data.attributes.has_questions}
        />}
      </div>
      {Object.keys(data.attributes.items).map(key => {
        const item = data.attributes.items[key]
        return (
          <div key={key}>
            <div className='flex gap-5'>
              <H3>
                {l('choice')} #{key / 100}
              </H3>
              <DeleteButton
                absolute={false}
                handleDelete={() => handleDeleteSubItem(key)}
              />
            </div>
            <div className='flex flex-row gap-5'>
              <Input
                label={l('label')}
                name='label'
                value={item.label}
                onChange={e => handleChoiceChange(e, key)}
              />
              <Input
                label={l('value')}
                name='value'
                onChange={e => handleChoiceChange(e, key)}
                value={item.value}
              />
            </div>
          </div>
        )
      })}
      <button onClick={handleClick} className='py-0.5 px-2 bg-red-900 rounded'>
        {l('add')}
      </button>
    </div>
  )
}

export function Section ({
  index,
  handleSubDrop,
  data,
  handleInputChange,
  handleAddRow,
  handleRadioChange,
  handleDelete,
  handleMove,
  handleCheckboxChange,
  handleViewQuestions
}) {
  const {l} = useLocale()

  const handleSubInputChange = (e, itemIndex) => {
    handleInputChange(e, index, itemIndex)
  }

  const handleSubRowAdd = (itemIndex, item) => {
    handleAddRow(index, item, itemIndex)
  }

  const handleSubRadioChange = (e, index, itemIndex, subItemIndex) => {
    handleRadioChange(e, index, itemIndex, subItemIndex)
  }

  const handleDeletion = () => {
    handleDelete(index)
  }

  const handlingCheckboxChange = e => {
    handleCheckboxChange(e, index)
  }

  return (
    <div className='w-full border border-gray-600 rounded-md bg-gray-800 p-4 mt-3 relative'>
      <FormButtons
        handleMove={action => handleMove(action, index)}
        handleDeletion={handleDeletion}
      />
      <H2>{l('section')}</H2>
      <div className='flex flex-row gap-3'>
        <Input
          label={l('title_section')}
          onChange={e => handleInputChange(e, index)}
          value={data.attributes.title}
          name='title'
          className='max-w-xs'
        />
        {data.attributes.is_multiple === true ? (
          <>
            <Input type="number" name="min_items" onChange={e => handleInputChange(e, index)} value={data.attributes.min_items} className='max-w-xs' label={l('minimum_items')} />
            <Input type="number" name="max_items" onChange={e => handleInputChange(e, index)} value={data.attributes.max_items} className='max-w-xs' label={l('max_items')} />
          </>
        ) : null}
      </div>
      <Checkbox
        label={l('is_multiple')}
        name='is_multiple'
        onChange={handlingCheckboxChange}
        checked={data.attributes.is_multiple}
      />
      {Object.keys(data.items).map(key =>
        React.createElement(components[data.items[key].component], {
          key,
          index,
          subIndex: key,
          data: data.items[key],
          handleInputChange,
          handleSubInputChange,
          subItem: true,
          handleAddRow,
          handleSubRowAdd,
          handleSubRadioChange,
          handleDelete,
          handleMove,
          handleCheckboxChange,
          canHaveQuestions: data.attributes.is_multiple === false,
          handleViewQuestions
        })
      )}
      <DropZone handleDrop={e => handleSubDrop(index, e)} />
    </div>
  )
}

export function DropZone ({handleDrop}) {
  const {l} = useLocale()

  return (
    <div
      className='w-full border-4 border-dashed py-7 border-gray-600 bg-gray-500 mt-5 mb-3 rounded-md flex flex-col items-center justify-center'
      onDragOver={e => e.preventDefault()}
      onDrop={handleDrop}
    >
      <img src='/img/plus.svg' alt='' className='max-w-[50px] mx-auto' />
      <p className='text-gray-900 font-bold m-0'>{l('drop_text')}</p>
    </div>
  )
}

export function InputField ({
  data,
  handleInputChange,
  handleSubInputChange,
  index,
  subIndex,
  subItem = false,
  handleDelete,
  handleMove,
  handleCheckboxChange,
  canHaveQuestions = true
}) {
  const {l} = useLocale()

  const handleChange = e => {
    if (subItem === true) {
      return handleSubInputChange(e, subIndex)
    }

    handleInputChange(e, index)
  }

  const handleDeletion = () => {
    if (subItem === true) {
      return handleDelete(index, subIndex)
    }

    handleDelete(index)
  }

  const handleMotion = action => {
    if (subItem === true) {
      return handleMove(action, index, subIndex)
    }

    handleMove(action, index)
  }

  const handlingCheckboxChange = e => {
    if (subItem === true) {
      return handleCheckboxChange(e, index, subIndex)
    }

    handleCheckboxChange(e, index)
  }

  return (
    <div className='w-full border border-gray-600 rounded-md bg-gray-800 p-4 mt-3 relative'>
      <FormButtons handleMove={handleMotion} handleDeletion={handleDeletion} />
      <H2>{l('input')}</H2>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
        {/* <Input
					name='name'
					label={l('name')}
					onChange={handleChange}
					value={data.attributes.name}
				/> */}
        <Input
          name='label'
          label={l('label')}
          onChange={handleChange}
          value={data.attributes.label}
        />
      </div>
      <SelectField
        onChange={handleChange}
        value={data.attributes.cols}
        className={'max-w-xs'}
        label={l('layout_cols')}
        options={LayoutSelectOptions}
        name="cols"
      />
      <div className='flex gap-3'>
        <Checkbox
          id={`required_${index}${subIndex}`}
          name={`is_required`}
          onChange={handlingCheckboxChange}
          label={l('is_required')}
          checked={data.attributes.is_required}
        />
        <Checkbox
          id={`useAsTitle_${index}${subIndex}`}
          name={`useAsTitle`}
          onChange={handlingCheckboxChange}
          label={l('use_as_title')}
          checked={data.attributes.useAsTitle}
        />
        {canHaveQuestions && <Checkbox
          id={`questions_${index}${subIndex}`}
          name={`has_questions`}
          onChange={handlingCheckboxChange}
          label={l('has_questions')}
          checked={data.attributes.has_questions}
        />}
      </div>
    </div>
  )
}

export function DatetimeField ({
  data,
  handleInputChange,
  handleSubInputChange,
  index,
  subIndex,
  subItem = false,
  handleDelete,
  handleMove,
  handleCheckboxChange,
  canHaveQuestions = true
}) {
  const {l} = useLocale()

  const handleChange = e => {
    if (subItem === true) {
      return handleSubInputChange(e, subIndex)
    }

    handleInputChange(e, index)
  }

  const handleDeletion = () => {
    if (subItem === true) {
      return handleDelete(index, subIndex)
    }

    handleDelete(index)
  }

  const handleMotion = action => {
    if (subItem === true) {
      return handleMove(action, index, subIndex)
    }

    handleMove(action, index)
  }

  const handlingCheckboxChange = e => {
    if (subItem === true) {
      return handleCheckboxChange(e, index, subIndex)
    }

    handleCheckboxChange(e, index)
  }

  return (
    <div className='w-full border border-gray-600 rounded-md bg-gray-800 p-4 mt-3 relative'>
      <FormButtons handleMove={handleMotion} handleDeletion={handleDeletion} />
      <H2>{l('datetime')}</H2>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
        {/* <Input
					name='name'
					label={l('name')}
					onChange={handleChange}
					value={data.attributes.name}
				/> */}
        <Input
          name='label'
          label={l('label')}
          onChange={handleChange}
          value={data.attributes.label}
        />
      </div>
      <SelectField
        onChange={handleChange}
        value={data.attributes.cols}
        className={'max-w-xs'}
        label={l('layout_cols')}
        options={LayoutSelectOptions}
        name="cols"
      />
      <div className='flex gap-3'>
        <Checkbox
          id={`required_${index}${subIndex}`}
          name={`is_required`}
          onChange={handlingCheckboxChange}
          label={l('is_required')}
          checked={data.attributes.is_required}
        />
        {canHaveQuestions && <Checkbox
          id={`questions_${index}${subIndex}`}
          name={`has_questions`}
          onChange={handlingCheckboxChange}
          label={l('has_questions')}
          checked={data.attributes.has_questions}
        />}
      </div>
    </div>
  )
}

export function Select ({
  data,
  handleSubInputChange,
  handleInputChange,
  subItem,
  index,
  subIndex,
  handleAddRow,
  handleSubRowAdd,
  handleSubRadioChange,
  handleRadioChange,
  handleDelete,
  handleMove,
  handleCheckboxChange,
  canHaveQuestions = true
}) {
  const {l} = useLocale()

  const item = {
    label: '',
    // name: '',
  }

  const handleChange = e => {
    if (subItem === true) {
      return handleSubInputChange(e, subIndex)
    }

    handleInputChange(e, index)
  }

  const handleChoiceChange = (e, key) => {
    if (subItem === true) {
      return handleSubRadioChange(e, index, subIndex, key)
    }
    handleRadioChange(e, index, key)
  }

  const handleClick = () => {
    if (subItem === true) {
      return handleSubRowAdd(subIndex, item)
    }
    handleAddRow(index, item)
  }

  const handleDeletion = () => {
    if (subItem === true) return handleDelete(index, subIndex)
    handleDelete(index)
  }

  const handleDeleteSubItem = key => {
    if (subItem === true) return handleDelete(index, subIndex, key)
    handleDelete(index, null, key)
  }

  const handleMotion = action => {
    if (subItem === true) {
      return handleMove(action, index, subIndex)
    }

    handleMove(action, index)
  }

  const handlingCheckboxChange = e => {
    if (subItem === true) {
      return handleCheckboxChange(e, index, subIndex)
    }

    handleCheckboxChange(e, index)
  }

  return (
    <div className='w-full border border-gray-600 rounded-md bg-gray-800 p-4 mt-3 relative'>
      <FormButtons handleMove={handleMotion} handleDeletion={handleDeletion} />
      <H2>{l('select')}</H2>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
        <Input
          name='label'
          label={l('label')}
          onChange={handleChange}
          value={data.attributes.label}
        />
        <Input
          name='name'
          label={l('name')}
          onChange={handleChange}
          value={data.attributes.name}
        />
      </div>
      <SelectField
        onChange={handleChange}
        value={data.attributes.cols}
        className={'max-w-xs'}
        label={l('layout_cols')}
        options={LayoutSelectOptions}
        name="cols"
      />
      <div className='flex gap-3'>
        <Checkbox
          id={`required_${index}${subIndex}`}
          name={`is_required`}
          onChange={handlingCheckboxChange}
          label={l('is_required')}
          checked={data.attributes.is_required}
        />
        {canHaveQuestions && <Checkbox
          id={`questions_${index}${subIndex}`}
          name={`has_questions`}
          onChange={handlingCheckboxChange}
          label={l('has_questions')}
          checked={data.attributes.has_questions}
        />}
      </div>
      {Object.keys(data.attributes.items).map(key => {
        const item = data.attributes.items[key]
        return (
          <div key={key}>
            <div className='flex gap-5'>
              <H3>
                {l('choice')} #{key}
              </H3>
              <DeleteButton
                absolute={false}
                handleDelete={() => handleDeleteSubItem(key)}
              />
            </div>
            <div className='flex flex-row gap-5'>
              <Input
                label={l('label')}
                name='label'
                value={item.label}
                onChange={e => handleChoiceChange(e, key)}
              />
              <Input
                label={l('value')}
                name='value'
                onChange={e => handleChoiceChange(e, key)}
                value={item.value}
              />
            </div>
          </div>
        )
      })}
      <button onClick={handleClick} className='py-0.5 px-2 bg-red-900 rounded'>
        {l('add')}
      </button>
    </div>
  )
}

export function Tag ({
  data,
  handleInputChange,
  handleSubInputChange,
  index,
  subIndex,
  subItem = false,
  handleDelete,
  handleMove,
  handleCheckboxChange,
  canHaveQuestions = true,
  handleAddRow,
  handleSubRowAdd,
  handleSubRadioChange,
  handleRadioChange
}) {
  const {l} = useLocale()

  const item = {
    label: '',
    // name: '',
  }

  const handleClick = () => {
    if (subItem === true) {
      return handleSubRowAdd(subIndex, item)
    }
    handleAddRow(index, item)
  }

  const handleDeleteSubItem = key => {
    if (subItem === true) return handleDelete(index, subIndex, key)
    handleDelete(index, null, key)
  }

  const handleChange = e => {
    if (subItem === true) {
      return handleSubInputChange(e, subIndex)
    }

    handleInputChange(e, index)
  }

  const handleDeletion = () => {
    if (subItem === true) {
      return handleDelete(index, subIndex)
    }

    handleDelete(index)
  }

  const handleMotion = action => {
    if (subItem === true) {
      return handleMove(action, index, subIndex)
    }

    handleMove(action, index)
  }

  const handlingCheckboxChange = e => {
    if (subItem === true) {
      return handleCheckboxChange(e, index, subIndex)
    }

    handleCheckboxChange(e, index)
  }

  const handleChoiceChange = (e, key) => {
    if (subItem === true) {
      return handleSubRadioChange(e, index, subIndex, key)
    }
    handleRadioChange(e, index, key)
  }

  return (
    <div className='w-full border border-gray-600 rounded-md bg-gray-800 p-4 mt-3 relative'>
      <FormButtons handleMove={handleMotion} handleDeletion={handleDeletion} />
      <H2>{l('tag')}</H2>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
        {/* <Input
					name='name'
					label={l('name')}
					onChange={handleChange}
					value={data.attributes.name}
				/> */}
        <Input
          name='label'
          label={l('label')}
          onChange={handleChange}
          value={data.attributes.label}
        />
      </div>
      <SelectField
        onChange={handleChange}
        value={data.attributes.cols}
        className={'max-w-xs'}
        label={l('layout_cols')}
        options={LayoutSelectOptions}
        name="cols"
      />
      <div className='flex gap-3'>
        <Checkbox
          id={`required_${index}${subIndex}`}
          name={`is_required`}
          onChange={handlingCheckboxChange}
          label={l('is_required')}
          checked={data.attributes.is_required}
        />
        {canHaveQuestions && <Checkbox
          id={`questions_${index}${subIndex}`}
          name={`has_questions`}
          onChange={handlingCheckboxChange}
          label={l('has_questions')}
          checked={data.attributes.has_questions}
        />}
      </div>
      {Object.keys(data.attributes.items || {}).map(key => {
        const item = data.attributes.items[key]
        return (
          <div key={key}>
            <div className='flex gap-5'>
              <H3>
                {l('choice')} #{key}
              </H3>
              <DeleteButton
                absolute={false}
                handleDelete={() => handleDeleteSubItem(key)}
              />
            </div>
            <div className='flex flex-row gap-5'>
              <Input
                label={l('label')}
                name='label'
                value={item.label}
                onChange={e => handleChoiceChange(e, key)}
              />
              <Input
                label={l('value')}
                name='value'
                onChange={e => handleChoiceChange(e, key)}
                value={item.value}
              />
            </div>
          </div>
        )
      })}
      <button onClick={handleClick} className='py-0.5 px-2 bg-red-900 rounded'>
        {l('add')}
      </button>
    </div>
  )
}

export function PredefinedQuestions ({
  data,
  handleInputChange,
  handleSubInputChange,
  index,
  subIndex,
  subItem = false,
  handleDelete,
  handleMove,
  handleViewQuestions,
}) {
  const {l} = useLocale()

  const handleDeletion = () => {
    if (subItem === true) {
      return handleDelete(index, subIndex)
    }

    handleDelete(index)
  }

  const handleMotion = action => {
    if (subItem === true) {
      return handleMove(action, index, subIndex)
    }

    handleMove(action, index)
  }

  const handleChange = e => {
    if (subItem === true) {
      return handleSubInputChange(e, subIndex)
    }

    handleInputChange(e, index)
  }

  const handlingViewQuestions = () => {
    if (subItem === true) {
      return handleViewQuestions(data, index, subIndex)
    }

    handleViewQuestions(data, index)
  }

  return (
    <div className='w-full border border-gray-600 rounded-md bg-gray-800 p-4 mt-3 relative'>
      <FormButtons handleMove={handleMotion} handleDeletion={handleDeletion} />
      <H2>{l('predefined_questions')}</H2>
      <div className='flex flex-row gap-3'>
        <Input
          name='label'
          label={l('label')}
          className={'max-w-xs'}
          onChange={handleChange}
          value={data.attributes.label}
        />
        <SelectField
          onChange={handleChange}
          value={data.attributes.cols}
          className={'max-w-xs'}
          label={l('layout_cols')}
          options={LayoutSelectOptions}
          name="cols"
        />
        <SelectField
          onChange={handleChange}
          value={data.attributes.style}
          options={[
            {label: 'Normal', value: 'normal'},
            {label: 'Radios', value: 'radios'},
            {label: 'Radio table', value: 'radio_table'},
          ]}
          className={'max-w-xs'}
          label={l('style')}
          name="style"
          withDefaultOption={false}
        />
      </div>
      <Button type='warning' onClick={handlingViewQuestions}>
        {l('view_questions')}
      </Button>
    </div>
  )
}

export function ImageGalleryItem ({
  data,
  handleInputChange,
  handleSubInputChange,
  index,
  subIndex,
  subItem = false,
  handleDelete,
  handleMove,
  handleCheckboxChange,
}) {
  const {l} = useLocale();

  const handleDeletion = () => {
    if (subItem === true) {
      return handleDelete(index, subIndex)
    }

    handleDelete(index)
  }

  const handleMotion = action => {
    if (subItem === true) {
      return handleMove(action, index, subIndex)
    }

    handleMove(action, index)
  }

  const handleChange = e => {
    if (subItem === true) {
      return handleSubInputChange(e, subIndex)
    }

    handleInputChange(e, index)
  }

  const handlingCheckboxChange = e => {
    if (subItem === true) {
      return handleCheckboxChange(e, index, subIndex)
    }

    handleCheckboxChange(e, index)
  }


  return (
    <div className='w-full border border-gray-600 rounded-md bg-gray-800 p-4 mt-3 relative'>
      <FormButtons handleMove={handleMotion} handleDeletion={handleDeletion} />
      <H2>{l('image_gallery_item')}</H2>
      <Input
        name='label'
        label={l('label')}
        className={'max-w-xs'}
        onChange={handleChange}
        value={data.attributes.label}
      />
      <SelectField
        onChange={handleChange}
        value={data.attributes.cols}
        className={'max-w-xs'}
        label={l('layout_cols')}
        options={LayoutSelectOptions}
        name="cols"
      />
      <div className='flex gap-3'>
        <Checkbox
          id={`required_${index}${subIndex}`}
          name={`is_required`}
          onChange={handlingCheckboxChange}
          label={l('is_required')}
          checked={data.attributes.is_required}
        />
      </div>
    </div>
  )
}

export function TitleField ({data, subItem, handleDelete, index, subIndex, handleMove, handleInputChange, handleSubInputChange}) {
  const {l} = useLocale()

  const handleDeletion = () => {
    if (subItem === true) {
      return handleDelete(index, subIndex)
    }

    handleDelete(index)
  }

  const handleMotion = action => {
    if (subItem === true) {
      return handleMove(action, index, subIndex)
    }

    handleMove(action, index)
  }

  const handleChange = e => {
    if (subItem === true) {
      return handleSubInputChange(e, subIndex)
    }

    handleInputChange(e, index)
  }

  return (
    <div className='w-full border border-gray-600 rounded-md bg-gray-800 p-4 mt-3 relative'>
      <FormButtons handleMove={handleMotion} handleDeletion={handleDeletion} />
      <H2>{l('title')}</H2>
      <Input
        name='label'
        label={l('label')}
        className={'max-w-xs'}
        onChange={handleChange}
        value={data.attributes.label}
      />
      <Input
        name='className'
        label={l('class_name')}
        className={'max-w-xs'}
        onChange={handleChange}
        value={data.attributes.className}
      />
    </div>
  )
}

export function NotFound () {
  const {l} = useLocale()

  return <div div className='w-full border border-gray-600 rounded-md bg-gray-800 p-4 mt-3 relative' >
    <H2 className={'justify-center'}>{l('item_not_found')}</H2>
  </div>
}

export function File ({
  data,
  handleInputChange,
  handleSubInputChange,
  index,
  subIndex,
  subItem = false,
  handleDelete,
  handleMove,
  handleCheckboxChange,
}) {
  const {l} = useLocale();

  const handleDeletion = () => {
    if (subItem === true) {
      return handleDelete(index, subIndex)
    }

    handleDelete(index)
  }

  const handleMotion = action => {
    if (subItem === true) {
      return handleMove(action, index, subIndex)
    }

    handleMove(action, index)
  }

  const handleChange = e => {
    if (subItem === true) {
      return handleSubInputChange(e, subIndex)
    }

    handleInputChange(e, index)
  }

  const handlingCheckboxChange = e => {
    if (subItem === true) {
      return handleCheckboxChange(e, index, subIndex)
    }

    handleCheckboxChange(e, index)
  }


  return (
    <div className='w-full border border-gray-600 rounded-md bg-gray-800 p-4 mt-3 relative'>
      <FormButtons handleMove={handleMotion} handleDeletion={handleDeletion} />
      <H2>{l('file_item')}</H2>
      <Input
        name='label'
        label={l('label')}
        className={'max-w-xs'}
        onChange={handleChange}
        value={data.attributes.label}
      />
      <SelectField
        onChange={handleChange}
        value={data.attributes.cols}
        className={'max-w-xs'}
        label={l('layout_cols')}
        options={LayoutSelectOptions}
        name="cols"
      />
      <div className='flex gap-3'>
        <Checkbox
          id={`required_${index}${subIndex}`}
          name={`is_required`}
          onChange={handlingCheckboxChange}
          label={l('is_required')}
          checked={data.attributes.is_required}
        />
      </div>
    </div>
  )
}