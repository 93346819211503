import React from 'react'
import Form from '../models/Form'
import useModal from '../hooks/useModal'
import { toast } from 'react-toastify'
import useLocale from './useLocale'

function useForms({ action = 'get', paginate = true } = {}) {
  const [ forms, setForms ] = React.useState([])
  const { showModal, openModal, closeModal } = useModal()
  const nameRef = React.useRef(null)
  const [ search, setSearch ] = React.useState('')
  const [ page, setPage ] = React.useState(1)
  const { l } = useLocale()

  const handleAdd = () => {
    const { value: name } = nameRef.current
    Form.save({ name })
      .then(res => {
        nameRef.current.value = ''
        getForms()
        closeModal()
      })
      .catch(err => toast.error(l('general_error')))
  }

  React.useEffect(() => {
    getForms()
  }, [ page, search ])

  const getForms = () => {
    Form[action](search, page, paginate)
      .then(res => setForms(res))
      .catch(err => toast.error(l('general_error')))
  }

  const handleDelete = id => {
    Form.delete(id)
      .then(res => getForms())
      .catch(err => toast.error(l('general_error')))
  }

  return {
    forms,
    handleDelete,
    nameRef,
    handleAdd,
    openModal,
    showModal,
    closeModal,
    setSearch,
    setPage,
    search
  }
}

export default useForms
