import React from 'react'
import {H1} from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import items from '../../config/items.json'
import ItemCard from '../../components/ItemCard'
import {DropZone, NotFound} from '../../components/ClinicCaseForm'
import components from '../../config/components'
import useCCLForm from '../../hooks/useCCLForm'
import Card from '../../components/Card'
import Button from '../../components/Button'
import {Input} from '../../components/FormComponents'
import CheckRole from '../../components/CheckRole'
import LegalsSelection from '../../components/LegalsSelection'
import RuleSetSelection from '../../components/RulesetSelection'
import {Link} from 'react-router-dom'
import ConfigSelection from '../../components/ConfigSelection'
import CheckFormPermission from '../../components/CheckFormPermission'
import useQuestions from '../../hooks/useQuestions'
import FormQuestions from '../../components/FormQuestions'

function Form () {
  const {l} = useLocale()
  const {
    formItems,
    setFormItems,
    handleAddRow,
    handleDrop,
    handleSubDrop,
    handleInputChange,
    handleRadioChange,
    handleDelete,
    handleSubmit,
    handleChange,
    id,
    handleMove,
    handleCheckboxChange,
  } = useCCLForm()

  const {
    handleViewQuestions,
    ...restOfQuestionsLogic
  } = useQuestions({form: formItems, setForm: setFormItems})

  return (
    <>
      <H1>
        <img src='/img/form.svg' className='w-[35px]' alt='' />
        {l('modify_form')}
      </H1>
      <div className='flex my-5'>
        <Card classname='flex-[5_5_0%] self-baseline h-auto mr-2 relative'>
          <div className='bg-gray-900 absolute top-0 left-0 right-0 rounded-tl-md rounded-tr-md py-1.5 px-5 flex gap-3'>
            <CheckRole has='select_legal_texts_from'>
              <LegalsSelection
                onChange={handleChange}
                formLegal={formItems.legalText || ''}
              />
            </CheckRole>
            <CheckRole has='select_ruleset_form'>
              <RuleSetSelection
                onChange={handleChange}
                ruleset={formItems.ruleset || formItems.ruleset?._id || ''}
              />
            </CheckRole>
            <CheckRole has='answer_forms'>
              <CheckFormPermission has={id}>
                <Link
                  to={`/form/${id}/answer`}
                  className='bg-gray-500 hover:bg-gray-400 px-2 py-1 rounded-md ease-in-out duration-200'
                >
                  <img src='/img/fill.svg' className='w-[25px]' alt='' />
                </Link>
              </CheckFormPermission>
            </CheckRole>
            <CheckRole has={'select_form_config'}>
              <ConfigSelection
                handleChange={handleChange}
                config={formItems.config || ''}
              />
            </CheckRole>
          </div>
          <div className='grid grid-cols-2 gap-4 mt-6'>
            <div>
              <Input
                label={l('name')}
                value={formItems.name}
                name='name'
                onChange={handleChange}
              />
            </div>
          </div>
          {Object.keys(formItems.items).map(key => {
            if (!(formItems.items[key].component in components)) return <NotFound />

            return React.createElement(
              components[formItems.items[key].component],
              {
                key,
                index: key,
                handleSubDrop,
                data: formItems.items[key],
                handleInputChange,
                handleAddRow,
                handleRadioChange,
                handleDelete,
                handleMove,
                handleCheckboxChange,
                handleViewQuestions
              }
            )
          })}
          <DropZone handleDrop={handleDrop} />
          <CheckRole has='edit_forms'>
            <Button type='primary' onClick={handleSubmit}>
              {l('save')}
            </Button>
          </CheckRole>
        </Card>
        <div className=' flex-1 border border-gray-600 p-5 rounded-md bg-gray-700 flex items-end'>
          <div className='w-full sticky top-4 self-start'>
            {items.map(el => (
              <ItemCard key={el.id} id={el.id}>
                {l(el.label)}
              </ItemCard>
            ))}
          </div>
        </div>
      </div>

      <FormQuestions {...restOfQuestionsLogic} />
    </>
  )
}

export default Form
