import React from 'react'
import Card from '../../components/Card'
import { H2, PageHeading } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Modal from '../../components/Modal'
import { Checkbox, Input } from '../../components/FormComponents'
import useStates from '../../hooks/useStates'
import CheckRole from '../../components/CheckRole'
import Button from '../../components/Button'
import DangerButton from '../../components/DangerButton'
import Paginator from '../../components/Paginator'

function StatesPage () {
  const { l } = useLocale()
  const {
    states,
    handleDelete,
    handleEdit,
    handleSubmit,
    showModal,
    nameRef,
    editableRef,
    labelRef,
    colorRef,
    closeModal,
    editing,
    handleAdd,
    addableRef,
    search,
    setSearch,
    omitRequiredRef,
    setPage,
    downloadableRef,
    canAssignDifficultyRef,
    canAssignQualityRef,
    isPublishedRef
  } = useStates()

  return (
    <>
      <PageHeading title={l('states')} image='/img/state.svg'>
        <CheckRole has='add_states'>
          <Button onClick={handleAdd}>{l('add')}</Button>
        </CheckRole>
      </PageHeading>
      <Card>
        <Input
          className='w-64'
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder={l('search')}
        />
        <table className='w-full'>
          <thead>
            <tr className='text-left border-b border-gray-600'>
              <th>{l('name')}</th>
              <th>{l('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {states.docs?.map(state => (
              <tr key={state._id}>
                <td>{state.name}</td>
                <td className='flex gap-3'>
                  <CheckRole has='edit_states'>
                    <button
                      onClick={() => handleEdit(state._id)}
                      className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
                    >
                      <img className='w-[25px]' src='/img/view.svg' alt='' />
                    </button>
                  </CheckRole>
                  <CheckRole has='delete_states'>
                    <DangerButton onClick={() => handleDelete(state._id)} />
                  </CheckRole>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Paginator setPage={setPage} meta={states} />
      </Card>
      <Modal show={showModal} onClose={closeModal}>
        <H2>{l(editing ? 'edit_state' : 'add_state')}</H2>
        <form onSubmit={handleSubmit}>
          <Input label={l('name')} name='name' reference={nameRef} />
          <Input label={l('label')} name='label' reference={labelRef} />
          <Input
            label={l('color')}
            type='color'
            name='color'
            reference={colorRef}
          />
          <Checkbox
            label={l('editable_form')}
            name='editable'
            reference={editableRef}
          />
          <Checkbox
            label={l('add_comments_form')}
            name='addComments'
            reference={addableRef}
          />
          <Checkbox
            label={l('omit_required')}
            name='omit_required'
            reference={omitRequiredRef}
          />
          <Checkbox
            label={l('can_download')}
            name='can_download'
            reference={downloadableRef}
          />
          <Checkbox
            label={l('can_assign_quality')}
            name='can_assign_quality'
            reference={canAssignQualityRef}
          />
          <Checkbox
            label={l('can_assign_difficulty')}
            name='can_assign_difficulty'
            reference={canAssignDifficultyRef}
          />
          <Checkbox
            label={l('is_published')}
            name='is_published'
            reference={isPublishedRef}
          />
          <Button type='secondary' full>
            {l(editing ? 'edit' : 'save')}
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default StatesPage
