import React from 'react'
import ClinicCase from '../models/ClinicCase'

const toggleRules = {
  mine: 'all',
  all: 'mine'
}

function useClinicCases (paginate = true) {
  const [clinicCases, setClinicCases] = React.useState([])
  const [filters, setFilters] = React.useState({ state: '', name: '', form: '' })
  const [viewMode, setViewMode] = React.useState('all')
  const [page, setPage] = React.useState(1)

  function getClinicCases () {
    if (viewMode === 'mine')
      ClinicCase.my(filters.name, filters.state, filters.form, page, paginate).then(res => setClinicCases(res))
    if (viewMode === 'all')
      ClinicCase.get(filters.name, filters.state, filters.form, page, paginate).then(res => setClinicCases(res))
  }

  React.useEffect(() => {
    getClinicCases()
  }, [filters, page, viewMode])

  const handleDelete = id => {
    ClinicCase.delete(id).then(res => getClinicCases())
  }

  const handleChange = (e) => {
    const { value, name } = e.target
    setFilters(old => ({
      ...old,
      [name]: value
    }))
  }

  function toggleViewMode () {
    setViewMode(toggleRules[viewMode])
  }

  return { clinicCases, handleDelete, handleChange, filters, setPage, toggleViewMode, viewMode }
}

export default useClinicCases
