import React from "react";
import { H2 } from "../Headings";
import { TagsInput } from "react-tag-input-component";
import useLocale from "../../hooks/useLocale";
import {
  Checkbox,
  Input,
  Radio,
  TextAreaField,
} from "../../components/FormComponents";
import componentsMap from "../../config/answerComponents";
import Button from "../Button";
import ShowElement from "./ShowElement";
import Divider from "../Divider";
import Editor from "../Editor";

export function Section({
  item,
  handleInputChange,
  index,
  handleCheckboxChange,
  errors,
  handleAddRow,
  handleDeleteRow,
  handleTagChange,
  handleViewQuestions,
  handleFileSelect,
  handleMetaChange,
}) {
  const { l } = useLocale();
  const isMultiple = item.attributes.is_multiple;
  const itemsKeys = Object.keys(item.items);

  const handleSubInputChange = (e, key, multipleIndex) =>
    handleInputChange(e, index, key, multipleIndex);

  const handleSubCheckboxChange = (e, key, multipleIndex) =>
    handleCheckboxChange(e, index, key, multipleIndex);

  const handleSubTagChange = (value, key, multipleIndex) =>
    handleTagChange(value, index, key, multipleIndex);

  const handleSubFileSelect = (e, key, multipleIndex) =>
    handleFileSelect(e, index, key, multipleIndex);

  const handleSubMetaChange = (e, key, multipleIndex) =>
    handleMetaChange(e, index, key, multipleIndex);

  return (
    <div className="col-span-12 grid grid-cols-12 gap-3 rounded-md border border-gray-400 px-5 py-2">
      <H2 className={"col-span-12"}>{item.attributes.title}</H2>
      {!isMultiple && (
        <ShowElement
          itemsKeys={itemsKeys}
          item={item.items}
          errors={errors}
          index={index}
          componentsMap={componentsMap}
          handleSubInputChange={handleSubInputChange}
          handleSubCheckboxChange={handleSubCheckboxChange}
          handleSubTagChange={handleSubTagChange}
          handleViewQuestions={handleViewQuestions}
          handleFileSelect={handleFileSelect}
          handleSubMetaChange={handleSubMetaChange}
        />
      )}
      {isMultiple && Array.isArray(item.items) && (
        <>
          {item.items.map((item, idx) => {
            const keys = Object.keys(item ? item : {});
            const errs = errors.filter((err) => err.index === idx);
            return (
              <React.Fragment key={idx}>
                <ShowElement
                  itemsKeys={keys}
                  item={item}
                  errors={errs}
                  index={index}
                  componentsMap={componentsMap}
                  handleSubInputChange={(e, key) =>
                    handleSubInputChange(e, key, idx)
                  }
                  handleSubCheckboxChange={(e, key) =>
                    handleSubCheckboxChange(e, key, idx)
                  }
                  handleSubTagChange={(e, key) =>
                    handleSubTagChange(e, key, idx)
                  }
                  handleSubFileSelect={(e, key) =>
                    handleSubFileSelect(e, key, idx)
                  }
                  handleSubMetaChange={(e, key) =>
                    handleSubMetaChange(e, key, idx)
                  }
                />
                <div className="col-span-12">
                  <Button
                    onClick={() => handleDeleteRow(index, idx)}
                    type="danger"
                    isSubmit={false}
                  >
                    {l("delete")}
                  </Button>
                  <Divider />
                </div>
              </React.Fragment>
            );
          })}
          <div className="grid-cols-12">
            <Button isSubmit={false} onClick={() => handleAddRow(index)}>
              {l("add")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export function TextArea({
  item,
  handleInputChange,
  index,
  Component,
  ErrorComponent,
}) {
  return (
    <>
      <div
        className={`mb-5`}
        style={{
          gridColumn: `span ${item.attributes.cols} / span ${item.attributes.cols}`,
        }}
      >
        <TextAreaField
          value={item.attributes.value}
          onChange={(e) => handleInputChange(e, index)}
          label={item.attributes.label}
        />
      </div>
      {Component}
      {ErrorComponent}
    </>
  );
}

export function EditorField({ item, handleInputChange, index }) {
  return (
    <div
      className="mb-5"
      style={{
        gridColumn: `span ${item.attributes.cols} / span ${item.attributes.cols}`,
      }}
    >
      <p className="my-2 font-bold">{item.attributes.label}</p>
      <Editor
        value={item.attributes.value || ""}
        handleChange={(event, editor) => {
          const data = editor.getData();
          handleInputChange(
            {
              target: {
                value: data,
              },
            },
            index,
          );
        }}
      />
    </div>
  );
}

export function Radios({
  item,
  handleInputChange,
  index,
  Component,
  ErrorComponent,
}) {
  const itemKeys = Object.keys(item.attributes.items);

  const handleChange = (e) => handleInputChange(e, index);

  return (
    <>
      <div
        style={{
          gridColumn: `span ${item.attributes.cols} / span ${item.attributes.cols}`,
        }}
      >
        <p className="my-2 font-bold">{item.attributes.label}</p>
        {itemKeys.map((key) => {
          const radio = item.attributes.items[key];
          return (
            <Radio
              key={`${key}${index}`}
              label={radio.label}
              onChange={handleChange}
              id={`${key}_${item.attributes.name}`}
              name={item.attributes.name}
              value={radio.value}
              checked={item.attributes.value === radio.value}
            />
          );
        })}
      </div>
      {Component}
      {ErrorComponent}
    </>
  );
}

export function Multiple({
  item,
  index,
  handleCheckboxChange,
  Component,
  ErrorComponent,
}) {
  const itemKeys = Object.keys(item.attributes.items);

  return (
    <>
      <div
        style={{
          gridColumn: `span ${item.attributes.cols} / span ${item.attributes.cols}`,
        }}
      >
        <p className="my-2 font-bold">{item.attributes.label}</p>
        {itemKeys.map((key) => {
          const checkbox = item.attributes.items[key];

          return (
            <Checkbox
              key={`${key}${index}`}
              label={checkbox.label}
              id={`${key}_${item.attributes.name}`}
              name={item.attributes.name}
              value={checkbox.value}
              checked={item.attributes.value?.some(
                (val) => checkbox.value === val,
              )}
              onChange={(e) => handleCheckboxChange(e, index)}
            />
          );
        })}
      </div>
      {Component}
      {ErrorComponent}
    </>
  );
}

export function InputField({
  item,
  handleInputChange,
  index,
  Component,
  ErrorComponent,
}) {
  return (
    <div
      style={{
        gridColumn: `span ${item.attributes.cols} / span ${item.attributes.cols}`,
      }}
    >
      <Input
        value={item.attributes.value}
        onChange={(e) => handleInputChange(e, index)}
        label={item.attributes.label}
      />
      {Component}
      {ErrorComponent}
    </div>
  );
}

export function Datetime({
  item,
  handleInputChange,
  index,
  Component,
  ErrorComponent,
}) {
  return (
    <div
      style={{
        gridColumn: `span ${item.attributes.cols} / span ${item.attributes.cols}`,
      }}
    >
      <Input
        type="date"
        value={item.attributes.value}
        onChange={(e) => handleInputChange(e, index)}
        label={item.attributes.label}
      />
      {Component}
      {ErrorComponent}
    </div>
  );
}

export function Select({
  item,
  handleInputChange,
  index,
  Component,
  ErrorComponent,
}) {
  return (
    <div
      className="my-2 mb-3 flex flex-1 flex-col gap-2"
      style={{
        gridColumn: `span ${item.attributes.cols} / span ${item.attributes.cols}`,
      }}
    >
      <label className="font-bold">{item.attributes.label}</label>
      <select
        onChange={(e) => handleInputChange(e, index)}
        value={item.attributes.value}
        className="w-full rounded-md px-2 py-1"
      >
        <option value=""></option>
        {Object.keys(item.attributes.items).map((idx) => {
          const option = item.attributes.items[idx];

          return (
            <option value={option.value} key={`${index}${idx}`}>
              {option.label}
            </option>
          );
        })}
      </select>
      {Component}
      {ErrorComponent}
    </div>
  );
}

export function TagField({
  item,
  handleTagChange,
  index,
  Component,
  ErrorComponent,
}) {
  return (
    <>
      <div
        className="my-2 flex flex-1 flex-col gap-2"
        style={{
          gridColumn: `span ${item.attributes.cols} / span ${item.attributes.cols}`,
        }}
      >
        <label className="font-bold">{item.attributes.label}</label>
        <TagsInput
          classNames={{ input: "px-2 py-1" }}
          onChange={(value) => handleTagChange(value, index)}
          value={item.attributes.value}
        />
      </div>
      {Component}
      {ErrorComponent}
    </>
  );
}

export function ImageGalleryField({
  item,
  handlePushGalleryFile,
  handleSetConsentDocument,
  Component,
  ErrorComponent,
  handleAddGalleryItem,
  files,
  handleDeleteFile,
  handleSetMetaField,
}) {
  const { l } = useLocale();

  return (
    <div
      style={{
        gridColumn: `span ${item.attributes.cols} / span ${item.attributes.cols}`,
      }}
    >
      <Input
        type="file"
        name={"consent"}
        onChange={handleSetConsentDocument}
        label={l("consent_document")}
      />
      <div className="w-full">
        {files
          ?.filter((file) => file.key !== "consent")
          .map((file, index) => (
            <>
              <div className="flex items-end gap-3">
                <Input
                  type="file"
                  name={"image"}
                  onChange={(e) => handlePushGalleryFile(e, index)}
                  label={l("image")}
                />
                <Button onClick={() => handleDeleteFile(index)} type="danger">
                  {l("delete")}
                </Button>
              </div>
              {file.file ? (
                <>
                  <TextAreaField
                    label={l("description")}
                    name="description"
                    onChange={(e) => handleSetMetaField(e, index)}
                  />
                  <label className="font-bold" htmlFor="">
                    {l("tag")}
                  </label>
                  <TagsInput
                    classNames={{ input: "px-2 py-1" }}
                    onChange={(value) =>
                      handleSetMetaField(
                        {
                          target: {
                            name: "keywords",
                            value,
                          },
                        },
                        index,
                      )
                    }
                  />
                </>
              ) : null}
              <Divider />
            </>
          ))}
      </div>
      <Button onClick={handleAddGalleryItem}>{l("add")}</Button>

      {item.attributes.value ? (
        <img src={item.attributes.value} alt="selected" className="max-w-sm" />
      ) : null}

      {Component}
      {ErrorComponent}
    </div>
  );
}

export function PredefinedQuestionsField({ item, Component, ErrorComponent }) {
  return (
    <>
      <div
        className="my-2 flex flex-1 flex-col gap-2"
        style={{
          gridColumn: `span ${item.attributes.cols} / span ${item.attributes.cols}`,
        }}
      >
        <label className="font-bold">{item.attributes.label}</label>
      </div>
      {Component}
      {ErrorComponent}
    </>
  );
}

export function TitleField({ item }) {
  return (
    <div
      className="my-2 flex flex-1 flex-col gap-2"
      style={{
        gridColumn: `span ${item.attributes.cols} / span ${item.attributes.cols}`,
      }}
    >
      <label className={`font-bold ${item.attributes.className}`}>
        {item.attributes.label}
      </label>
    </div>
  );
}

export function FileField({
  item,
  handleInputChange,
  index,
  Component,
  ErrorComponent,
  handleMetaChange,
}) {
  const { l } = useLocale();

  async function handleFileSelect(e) {
    const file = e.target.files[0];

    const fd = new FormData();
    fd.append("upload", file);

    const res = await window.fetch(`${process.env.REACT_APP_API_URL}images`, {
      method: "POST",
      body: fd,
      credentials: "include",
    });
    const data = await res.json();
    handleInputChange(
      {
        target: {
          value: data.url,
        },
      },
      index,
    );
    console.log(data);
  }

  return (
    <div
      style={{
        gridColumn: `span ${item.attributes.cols} / span ${item.attributes.cols}`,
      }}
    >
      <Input
        type="file"
        onChange={handleFileSelect}
        label={item.attributes.label}
      />
      <div className="grid w-full grid-cols-2 gap-3">
        <div className="flex flex-col">
          <label htmlFor="">{l("description")}</label>
          <textarea
            rows={5}
            className="rounded-md text-black"
            name="description"
            onChange={(e) => handleMetaChange(e, index)}
          ></textarea>
        </div>
        <div>
          <label htmlFor="">{l("tag")}</label>
          <TagsInput
            onChange={(value) =>
              handleMetaChange(
                {
                  target: {
                    name: "keywords",
                    value,
                  },
                },
                index,
              )
            }
            classNames={{ input: "px-2 py-1" }}
          />
        </div>
      </div>

      {item.attributes.value ? (
        <img src={item.attributes.value} alt="selected" className="max-w-sm" />
      ) : null}

      {Component}
      {ErrorComponent}
    </div>
  );
}
