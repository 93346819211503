import React from 'react'
import { PageHeading } from '../../components/Headings'
import Card from '../../components/Card'
import Button from '../../components/Button'
import SmtpConfig from '../../components/SmtpConfig'
import CheckRole from '../../components/CheckRole'
import { Input } from '../../components/FormComponents'
import Divider from '../../components/Divider'
import useLocale from '../../hooks/useLocale'
import useConfig from '../../hooks/useConfig'
import UserConfig from '../../components/UserConfig'
import GeneralConfig from '../../components/GeneralConfig'
import GamificationConfig from '../../components/GamificationConfig'

function ShowConfig () {
  const { l } = useLocale()
  const {
    config,
    handleNameChange,
    handleChange,
    handleCheckboxChange,
    handleSave,
    addClassifier,
    removeClassifier
  } = useConfig()

  return (
    <>
      <PageHeading
        title={`${l('view_config')} ${config.name}`}
        image={'/img/cog.svg'}
      />
      <Card>
        <Input
          label={l('name')}
          name={'name'}
          value={config.name}
          onChange={handleNameChange}
        />
        <Divider />

        <CheckRole has='edit_general_config'>
          <GeneralConfig
            config={config}
            handleChange={e => handleChange(e, 'general')}
          // handleCheckboxChange={e => handleCheckboxChange(e, 'smtp')}
          />
        </CheckRole>

        <CheckRole has={'edit_smtp_config'}>
          <SmtpConfig
            config={config}
            handleChange={e => handleChange(e, 'smtp')}
            handleCheckboxChange={e => handleCheckboxChange(e, 'smtp')}
          />
          <Divider />
        </CheckRole>

        <CheckRole has={'edit_user_config'}>
          <UserConfig
            config={config}
            handleChange={e => handleChange(e, 'user')}
            handleCheckboxChange={e => handleCheckboxChange(e, 'user')}
          />
          <Divider />
        </CheckRole>

        <CheckRole has="edit_gamification_config">
          <GamificationConfig
            config={config}
            handleChange={e => handleChange(e, 'gamification')}
            handleCheckboxChange={e => handleCheckboxChange(e, 'gamification')}
            addClassifier={addClassifier}
            removeClassifier={removeClassifier}
          />
        </CheckRole>
      </Card>
      <CheckRole has={'edit_config'}>
        <Button onClick={handleSave}>{l('save')}</Button>
      </CheckRole>
    </>
  )
}

export default ShowConfig
